<template>
  <div>
    <div v-if="empty" class="text-muted mt-30">No comments yet.</div>

    <div v-if="loading">
      <div class="chat-bubble">
        <Preloader />
      </div>
    </div>
    <div v-if="!empty">
      <div class="chat-container-wrapper">
        <div class="chat-bubble" :class="`${record.direction}-chat`" v-for="record in records" :key="record.id">
          <div class="chat-message"><p v-html="record.content"></p></div>
          <div class="sender-details">
            <img :src="avatar" class="sender-avatar img-xs rounded-circle" />
            <p class="seen-text">{{ record.author_role_name }}</p>
            <p class="seen-text">{{ record.created_at }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState } from 'vuex'
import Preloader from 'src/components/preloader.vue'

export default defineComponent({
  props: {
    target: {
      type: String
    },
    collection: {
      type: String,
      default: 'notes'
    },
    avatar: {
      type: String,
      default: '/assets/face1.jpg'
    }
  },
  components: {
    Preloader
  },
  computed: {
    empty() {
      return !this.loading && (!this.records || this.records.length == 0)
    },
    ...mapState('orders', {
      loading(state) {
        return state['ORDER_DETAIL_READ_LOADING']
      },
      records(state) {
        const item = state['ORDER_DETAIL_READ_DATA']

        return item && item.data && item.data[this.collection]
      }
    }),
  }
})
</script>
