<template>
  <div>
    <div v-if="loading">
      <Preloader />
    </div>
    <div v-else>
      <div class="ticket-form">
        <div class="ticket-form-wrapper">
          <div class="ticket-form-text-wrapper">
            <textarea
              v-model.trim="message"
              :disabled="submitting"
              type="text"
              class="form-control">
            </textarea>
          </div>
          <div class="ticket-form-footer-wrapper">
            <div class="ticket-form-buttons-wrapper">
              <div class="ticket-form-button-container">
                <div v-if="submitting">
                  <Dualball />
                </div>
                <button class="btn btn-inverse-info btn-sm" :disabled="submitDisabled" @click="createMessage" v-else>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="ticket-messages">
        <div v-for="message in ticket.messages" :key="message.id" class="ticket-message">
          <div class="ticket-message-body">
            <div class="ticket-message-author">{{message.author_name}}</div>
            <div class="ticket-message-content" v-html="message.content"></div>
          </div>
          <div class="ticket-message-footer">
            <span class="ticket-message-date">{{formatDate(message.created_at)}}</span>
            <div class="clearfix"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import { format, parseISO } from 'date-fns'

import Preloader from 'src/components/preloader.vue'
import Dualball from 'src/components/dualball.vue'
import StateLabel from 'src/widgets/state_label.vue'

export default defineComponent({
  props: {
    target: {
      type: String
    },
    uuid: {
      type: String
    },
  },
  components: {
    Preloader,
    StateLabel,
    Dualball
  },
  created() {
    this.detailReadRequest({order_id: this.target, id: this.uuid})
	},
  data() {
    return {
      message: null
    }
  },
  computed: {
    submitDisabled() {
      return !this.message || this.message.length == 0
    },
    ...mapGetters('tickets', {
      loading: 'loading',
      submitting: 'submitting',
      ticket: 'detail',
    })
  },
  methods: {
    createMessage() {
      const data = {order_id: this.target, id: this.uuid, message: this.message}
      this.createMessageRequest(data).then(({data}) => {
        if (data && data['data']) {
          this.$toast.open({
            message: 'Message was successfully created.',
            type: 'success'
          })
        }

        this.message = null
      })
    },
    formatDate(value) {
      const date = parseISO(value)
      return format(date, 'dd MMM yy HH:mm')
    },
    ...mapActions('tickets', [
      'detailReadRequest',
      'createMessageRequest'
    ])
  }
})
</script>
