import * as types from 'src/store/mutation-types'
import api from 'src/utils/api'

// initial state
const state = () => ({
  ORDER_DETAIL_READ_LOADING: false,
  ORDER_DETAIL_READ_DATA: null
})

// getters
const getters = {
  readAttr: (state, getters) => (name) => {
    const resource = state[types.ORDER_DETAIL_READ.stateKey]

    if (resource && resource.data) {
      return resource.data[name]
    }

    return null
  },
  resourceCount: (state, getters) => (name) => {
    const data = getters.readAttr(name)

    if (data) {
      return data.length
    }

    return 0
  },
  materialsCount: (state, getters) => {
    const items = [
      'additional_materials',
      'attachments',
      'draft_papers',
      'papers',
      'plagiarism_reports',
      'revision_papers'
    ]
    const reducer = (previousValue, currentValue) => previousValue + currentValue

    return items.map(getters.resourceCount).reduce(reducer)
  },
  notesCount: (state, getters, rootState, rootGetters) => {
    const total = rootGetters['comments/count'] +
      rootGetters['tickets/count'] +
      getters.resourceCount('notes');

    return total;
  },

  getCurrentStats: (state, getters) => (name) => {
    switch(name) {
      case 'materialsCount':
        return getters.materialsCount
      case 'notesCount':
        return getters.notesCount
      default:
        return getters.readAttr(name)
    }
  },

  loading: state => {
    return state[types.ORDER_DETAIL_READ.loadingKey]
  },

  canDownload: (state, getters) => {
    return getters.readAttr('can_download') === true
  },

  canUpdate: (state, getters) => {
    return getters.readAttr('can_update') === true
  }
}

// actions
const actions = {
  detailReadRequest(store, {id}) {
    return api.get(store, {url: `/orders/${id}.json`, mutationTypes: types.ORDER_DETAIL_READ})
  }
}

// mutations
const mutations = {
  [types.ORDER_DETAIL_READ.PENDING] (state) {
    state[types.ORDER_DETAIL_READ.loadingKey] = true
	},
  [types.ORDER_DETAIL_READ.SUCCESS] (state, {data}) {
		state[types.ORDER_DETAIL_READ.loadingKey] = false
    state[types.ORDER_DETAIL_READ.stateKey] = data
	},
  [types.ORDER_DETAIL_READ.FAILURE] (state) {
    state[types.ORDER_DETAIL_READ.loadingKey] = false
    state[types.ORDER_DETAIL_READ.stateKey] = null
	},

  createRecord(state, {association, record}) {
    let data = state[types.ORDER_DETAIL_READ.stateKey].data

    if (data[association]) {
      data[association].push(record)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
