<template>
  <Calendar
    class="work-calendar max-w-full"
    :masks="masks"
    :first-day-of-week="2"
    @dayclick="onDateClick"
    @update:to-page="onPageChange"
    :attributes="attributes"
    :available-dates="availableDates"
    color="purple"
    disable-page-swipe
    trim-weeks
    is-expanded>
    <template v-slot:day-content="{ day, attributes, dayEvents, dayProps }">
      <div class="vc-day-label vc-day-box-center-center">
        <span
          @click="dayEvents.onClick"
          :tabindex="dayProps.tabindex"
          :aria-label="dayProps['aria-label']"
          :aria-disabled="dayProps['aria-disabled']"
          :role="dayProps.role"
          :class="{ 'is-disabled': day.isDisabled }"
          class="vc-day-content vc-focusable">
          {{ day.label }}
        </span>
      </div>

      <div class="flex flex-col h-full z-10 overflow-hidden" v-for="attr in customOnly(attributes)" :key="attr.key">
        <div class="flex-grow overflow-y-auto overflow-x-auto">
          <p class="text-xs leading-tight rounded-sm p-1 mt-0 mb-1 vc-completed-orders">
            {{ attr.customData && attr.customData.title }}
          </p>
        </div>
      </div>
    </template>
  </Calendar>

  <div class="bar-preloader" v-if="loading">
    <div class="bar-center">
      <Preloader />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import { Calendar } from 'v-calendar'
import { addDays } from 'date-fns'
import { filter } from 'lodash-es'

import Preloader from 'src/components/preloader.vue'
import { isMobile } from 'src/utils/common'

export default defineComponent({
  props: {
    dailylimit: {
      type: String
    }
  },
  components: {
    Calendar,
    Preloader
  },
  computed: {
    masks() {
      const weekdays = isMobile() ? 'W' : 'WWWW'

      return {
        weekdays: weekdays
      }
    },
    availableDates() {
      return {
        start: addDays(new Date(), 1),
        end: null
      }
    },
    dates() {
      return this.days.map(day => day.date);
    },
    attributes() {
      const dailylimit = parseInt(this.dailylimit, 10)

      const dates = this.dates.map(date => ({
        highlight: true,
        dates: date,
      }))

      const stats = this.stats.map(item => ({
        customData: {
          title: `${item.completed_orders_count} - done orders`,
        },
        bar: true,
        highlight: item.completed_orders_count >= dailylimit ? 'green' : 'red',
        dates: new Date(item.report_date)
      }))

      return dates.concat(stats)
    },
    customOnly() {
      return (items) => {
        return filter(items, (item) => { return item.customData && item.customData.title})
      }
    },
    ...mapGetters('work_schedules', [
      'getListByDate',
      'loading',
      'error'
    ])
  },
  watch: {
    error(ex) {
      if (ex && ex.message) {
        this.$toast.open({
          message: ex.message,
          type: 'error'
        })
      }
    }
  },
  data() {
    return {
      days: [],
      stats: []
    }
  },
  methods: {
    onDateClick(day) {
      if (day.isDisabled) {
        return false
      } else {
        this.toggleDay(day)
      }
    },
    onPageChange(page) {
      this.detailReadRequest(page).then((response) => {
        this.stats = response.data.data
      })

      this.listLazyFetch(page).then((days) => {
        this.days = days
      })
    },
    ...mapActions('work_schedules', [
      'listLazyFetch',
      'toggleDay'
    ]),
    ...mapActions('kpi', [
      'detailReadRequest'
    ])
  }
})
</script>

<style>
.work-calendar.vc-container {
  --day-border: 1px solid #b8c2cc;
  --day-border-highlight: 1px solid #b8c2cc;
  --day-width: 100px;
  --day-height: 100px;
  --weekday-bg: #f8fafc;
  --weekday-border: 1px solid #eaeaea;
  border-radius: 0;
  width: 100%;
}

.work-calendar.vc-container .vc-header {
  background-color: #f1f5f8;
  padding: 10px 0;
}
.work-calendar.vc-container .vc-weeks {
  padding: 0;
}
.work-calendar.vc-container .vc-weekday {
  background-color: var(--weekday-bg);
  border-bottom: var(--weekday-border);
  border-top: var(--weekday-border);
  padding: 5px 0;
}
.work-calendar.vc-container .vc-day {
  padding: 0 5px 3px 5px;
  text-align: left;
  height: var(--day-height);
  min-width: var(--day-width);
  background-color: white;
}

.work-calendar.vc-container .vc-day-label {
  padding-top: 36px;
}
.work-calendar.vc-container .vc-completed-orders {
  text-align: center;
  font-size: 12px;
}
.work-calendar.vc-container .vc-day > div.flex.flex-col {
  margin: 5px;
}

.work-calendar.vc-container .vc-day.weekday-1,
.work-calendar.vc-container .vc-day.weekday-7 {
  background-color: #eff8ff;
}
.work-calendar.vc-container .vc-day:not(.on-bottom) {
  border-bottom: var(--day-border);
}
.work-calendar.vc-container .vc-day:not(.on-right) {
  border-right: var(--day-border);
}

.work-calendar.vc-container .vc-day-dots {
  margin-bottom: 5px;
}

.work-calendar.vc-container .vc-day.is-today {
  background-color: var(--green-200);
  opacity: 1;
}

.work-calendar.vc-container .vc-day.is-not-in-month * {
  opacity: 1;
}
.work-calendar.vc-container .vc-day .vc-highlights + .vc-day-label span {
  font-weight: var(--font-bold);
  color: var(--white);
}

@media (max-width: 480px) {
  .work-calendar.vc-container {
    --day-width: 32px;
    --day-height: 32px;
  }

  .work-calendar.vc-container .vc-day-label {
    padding-top: 2px;
  }

  .work-calendar.vc-container .vc-completed-orders {
    display: none;
  }
}
</style>
