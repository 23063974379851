//Add active class to nav-link based on url dynamically
//Active class can be hard coded directly in html file also as required

import $ from 'jquery'
import 'select2'
import 'select2/dist/css/select2.css'

import 'daterangepicker'
import 'daterangepicker/daterangepicker.css'

import 'bootstrap-datetime-picker'
import 'bootstrap-datetime-picker/css/bootstrap-datetimepicker.min.css'

import PaymentAccount from 'src/payment_account'

import { subDays, startOfMonth, endOfMonth, subMonths, startOfYear } from 'date-fns'

$.fn.inputFilter = function(inputFilter) {
  return this.on("input keydown keyup mousedown mouseup select contextmenu drop", function() {
    if (inputFilter(this.value)) {
      this.oldValue = this.value;
      this.oldSelectionStart = this.selectionStart;
      this.oldSelectionEnd = this.selectionEnd;
    } else if (this.hasOwnProperty("oldValue")) {
      this.value = this.oldValue;
      this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
    } else {
      this.value = "";
    }
  });
}

function addActiveClass(element, current) {
  if (current === "") {
    //for root url
    if (element.attr('href') == '/') {
      element.parents('.nav-item').last().addClass('active');
      if (element.parents('.sub-menu').length) {
        element.closest('.collapse').addClass('show');
        element.addClass('active');
      }
    }
  } else {
    //for other url
    if (element.attr('href').indexOf(current) !== -1) {
      element.parents('.nav-item').last().addClass('active');

      if (element.parents('.sub-menu').length) {
        element.closest('.collapse').collapse('show');
        element.addClass('active');
      }

      if (element.parents('.submenu-item').length) {
        element.addClass('active');
      }
    }
  }
}

function initSidebar() {
  var sidebar = $('.sidebar')
  var current = location.pathname

  $('.nav li a', sidebar).each(function() {
    var $this = $(this);
    addActiveClass($this, current);
  })

  $('.horizontal-menu .nav li a').each(function() {
    var $this = $(this);
    addActiveClass($this, current);
  })

  $('[data-toggle="offcanvas"]').on('click', function() {
    $('.sidebar-offcanvas').toggleClass('active')
  })
}

function initSidebarMenu() {
  var body = $('body')

  $(document).on('mouseenter mouseleave', '.sidebar .nav-item', function(ev) {
    var sidebarIconOnly = body.hasClass("sidebar-icon-only")
    var sidebarFixed = body.hasClass("sidebar-fixed")

    if (!('ontouchstart' in document.documentElement)) {
      if (sidebarIconOnly) {
        if (sidebarFixed) {
          if (ev.type === 'mouseenter') {
            body.removeClass('sidebar-icon-only');
          }
        } else {
          var $menuItem = $(this);
          if (ev.type === 'mouseenter') {
            $menuItem.addClass('hover-open')
          } else {
            $menuItem.removeClass('hover-open')
          }
        }
      }
    }
  })
}

function initFullscrean() {
  $("#fullscreen-button").on("click", function toggleFullScreen() {
    if ((document.fullScreenElement !== undefined && document.fullScreenElement === null) || (document.msFullscreenElement !== undefined && document.msFullscreenElement === null) || (document.mozFullScreen !== undefined && !document.mozFullScreen) || (document.webkitIsFullScreen !== undefined && !document.webkitIsFullScreen)) {
      if (document.documentElement.requestFullScreen) {
        document.documentElement.requestFullScreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
      } else if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen();
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  })
}

function initPerfectScrollbar(){
  var body = $('body');

  //Applying perfect scrollbar
  if (!body.hasClass("rtl")) {
    if (body.hasClass("sidebar-fixed")) {
      var fixedSidebarScroll = new PerfectScrollbar('#sidebar .nav');
    }
  }
}

function initPlugins(){
  var body = $('body');

  $('[data-toggle="minimize"]').on("click", function() {
    if ((body.hasClass('sidebar-toggle-display')) || (body.hasClass('sidebar-absolute'))) {
      body.toggleClass('sidebar-hidden');
    } else {
      body.toggleClass('sidebar-icon-only');
    }
  })

  //checkbox and radios
  $(".form-check label, .form-radio label").append('<i class="input-helper"></i>')
  $('.js-states, form.simple_form select.select').select2({
    minimumResultsForSearch: 20,
    allowClear: true,
    placeholder: 'Select value'
  })
}

function initDateRangePicker(selector) {
  const today = new Date()
  const yesterday = subDays(new Date(), 1)
  const locale = {
    firstDay: 1,
    format: 'DD/MM/YYYY HH:mm',
    separator: " to "
  }

  $(selector).daterangepicker({
    showCustomRangeLabel: true,
    timePicker24Hour: true,
    autoUpdateInput: false,
    locale: locale,
    ranges: {
      'Today': [today, today],
      'Yesterday': [yesterday, yesterday],
      'Last 7 Days': [subDays(new Date(), 6), today],
      'Last 30 Days': [subDays(new Date(), 29), today],
      'This Month': [startOfMonth(today), endOfMonth(today)],
      'Last Month': [startOfMonth(subMonths(today, 1)), endOfMonth(subMonths(today, 1))],
      'Year to current': [startOfYear(today), today]
    }
  })

  $(selector).on('apply.daterangepicker', function(ev, picker) {
    const format = ($(this).data('format') || locale.format)
    $(this).val(picker.startDate.format(format) + locale.separator + picker.endDate.format(format))
  });

  $(selector).on('cancel.daterangepicker', function(ev, picker) {
    $(this).val('')
  });
}

function initRevisionCommentInit() {
  const select = $('#reassign_reassign_type_id')

  const on_change = function() {
    var reassign_type_id = select.val()

    $('.reassign_type_control').hide()
    $(`.reassign_type_${reassign_type_id}`).show()
  }

  $('#reassign_reassign_type_id').on("change", on_change)
  on_change()
}

function initToggleCheckbox() {
  const on_input = (event) => {
    const element = $(event.target)

    if (element && element.data('toggle')) {
      const submit = $(`#${element.data('toggle')}`)

      if (element.is(':checked')) {
        submit.removeAttr('disabled')
      } else {
        submit.attr('disabled', 'disabled')
      }
    }
  }

  $('input[type=checkbox][data-toggle]').on('change', on_input)
}

function initFormInputs(){
  $('input.number-input').inputFilter(function(value) {
    return /^\d*$/.test(value)
  })

  $('input.phone-number-input').inputFilter(function(value) {
    return /^[\d\-\(\)]*$/.test(value)
  })
}

function initTextEditors(cssName){
  const elements = document.getElementsByClassName(cssName)

  Array.prototype.forEach.call(elements, (element) => {
    ClassicEditor.create(element, {
      removePlugins: [ 'Heading', 'Link', 'CKFinder' ],
      toolbar: [ 'bold', 'italic', '|', "indent", "outdent", '|',
                 'numberedList', 'bulletedList', '|', 'blockQuote' ]
    })
  })
}

function initOrderEventSelect(){
  const sidebar = document.getElementById('rating_order_event_sidebar')

  const on_change = (event) => {
    const element = $(event.target)

    if (element) {
      if (element.is(':checked') && element.val() == 'corrections') {
        sidebar.style.display = 'block'
      } else {
        sidebar.style.display = 'none'
      }
    }
  }

  $('fieldset.rating_order_event input.radio_buttons').on('change', on_change)
}

function initDatetimePicker(selector){
  $(selector).datetimepicker('remove')

  $(selector).datetimepicker({
    format: 'dd/mm/yyyy hh:ii',
    autoclose: true,
    todayBtn: false,
    minuteStep: 5
  })
}

export default {
  start: function() {
    document.addEventListener('turbolinks:load', () => {
      initSidebar()
      initSidebarMenu()
      initFullscrean()
      initPerfectScrollbar()
      initPlugins()
      initRevisionCommentInit()
      initDateRangePicker('input.daterange')
      initDatetimePicker('input.date_time_picker')
      initToggleCheckbox()
      initFormInputs()
      initTextEditors('text-editor-ui')
      initOrderEventSelect()

      new PaymentAccount().init()
    })
  }
}
