<template>
  <order-table :target="target" :collection="collection">
    <template v-slot:header>
      <tr>
        <th>Feature type</th>
        <th>Title</th>
        <th>Quantity</th>
        <th>State</th>
      </tr>
    </template>

    <template v-slot:row="child">
      <td>{{ child.record.type_title }}</td>
      <td>{{ child.record.title }}</td>
      <td>{{ child.record.quantity }}</td>
      <td><state-label :name="child.record.state_name" /></td>
    </template>
  </order-table>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import OrderTable from 'src/widgets/order_table_app'
import StateLabel from 'src/widgets/state_label.vue'

export default defineComponent({
  props: {
    target: {
      type: String
    },
    collection: {
      type: String
    }
  },
  components: {
    OrderTable,
    StateLabel
  }
})
</script>
