import { sumBy } from 'lodash-es'

import * as types from 'src/store/mutation-types'
import api from 'src/utils/api'

// initial state
const state = () => ({
  KPI_STATS_LOADING: false,
  KPI_STATS_DATA: null
})

// getters
const getters = {
  getCompletedOrdersCount: (state, getters) => {
    return sumBy(state[types.KPI_STATS.stateKey], 'completed_orders_count')
  },
}

// actions
const actions = {
  detailReadRequest(store, data) {
    return api.get(store, {
      url: '/kpi/statistics/monthly.json',
      params: data,
      mutationTypes: types.KPI_STATS
    })
  }
}

// mutations
const mutations = {
  [types.KPI_STATS.PENDING] (state) {
    state[types.KPI_STATS.loadingKey] = true
	},
  [types.KPI_STATS.SUCCESS] (state, {data}) {
		state[types.KPI_STATS.loadingKey] = false
    state[types.KPI_STATS.stateKey] = data.data
	},
  [types.KPI_STATS.FAILURE] (state) {
    state[types.KPI_STATS.loadingKey] = false
    state[types.KPI_STATS.stateKey] = null
	}
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
