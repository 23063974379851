<template>
  <span v-if="!loading">{{ content }}</span>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'

export default defineComponent({
  props: {
    name: {
      type: String
    }
  },
  computed: {
    content() {
      return this.getCurrentStats(this.name)
    },
    ...mapGetters('orders', [
      'getCurrentStats',
      'loading'
    ])
  }
})
</script>
