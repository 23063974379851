import { Toast } from 'bootstrap';

function showNotice(element) {
  new Toast(element, {
    animation: true,
    autohide: true,
    delay: 15000
  }).show()
}

function initNotifications() {
  $('.toast').each(function(){
    showNotice(this)
  })
}

export default {
  start: function() {
    $(document).ready(initNotifications)
  }
}
