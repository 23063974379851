import $ from 'jquery'

class PaymentAccount {
  element = null;
  options = null;

  constructor(options: object) {
    const defaults = {
      input: '.payment_type_id_input',
      fieldset: '.payment_account_fields'
    }

    this.options = {...defaults, ...options};
  }

  init() {
    this.element = $(this.options.input)

    if (this.element.length) {
      this.showFieldsForAccount()

      this.element.on('change', () => {
        this.showFieldsForAccount()
      })
    }
  }

  showFieldsForAccount() {
    const fieldset = $(this.options.fieldset)
    const uid = parseInt(this.element.select2('data')[0].id, 10)

    fieldset.find('.form-group').hide().find('input').prop('disabled', true)

    const selected_inputs = fieldset.find(`[data-payment-type-id=${uid}]`)
    selected_inputs.prop('disabled', false)
    selected_inputs.parent().show()
  }
}

export default PaymentAccount
