<template>
  <div>
    <a
      v-if="allowDownload"
      class="btn btn-outline-primary btn-sm"
      @click.stop.prevent="download"
      href="#download-all">
      Download all
    </a>
    <Dualball v-if="loading" />
    <div class="modal micromodal-slide" :id="modalUUID" aria-hidden="true">
      <div class="modal__overlay" tabindex="-1" data-micromodal-close>
        <div class="modal__container" role="dialog" aria-modal="true" aria-labelledby="modal-1-title">
          <header class="modal__header">
            <h2 class="modal__title">
              Archive
            </h2>
            <button class="modal__close" aria-label="Close modal" data-micromodal-close></button>
          </header>
          <main class="modal__content">
            <p>
              Your archive is ready. Click download button to save it.
            </p>
          </main>
          <footer class="modal__footer">
            <button :download="filename" class="modal__btn modal__btn-primary" @click="save">Download</button>
            <button class="modal__btn ml-2" data-micromodal-close aria-label="Close this dialog window">Close</button>
          </footer>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState } from 'vuex'
import { downloadZip } from 'client-zip'
import MicroModal from 'micromodal';

import { downloadAssetsGenerator, genRandomUUID } from 'src/utils/common'
import Dualball from 'src/components/dualball.vue'

export default defineComponent({
  props: {
    target: {
      type: String
    },
    collection: {
      type: String
    },
    modalUUID: {
      type: String,
      default: 'modal-' + genRandomUUID()
    }
  },
  components: {
    Dualball
  },
  data() {
    return {
      loading: false,
      link: null
    }
  },
  computed: {
    filename() {
      return this.target + "_" + this.collection + ".zip"
    },
    linkId() {
      return this.modalUUID + '-link'
    },
    allowDownload() {
      return this.loading == false && this.records && this.records.length >= 2;
    },
    ...mapState('orders', {
      records(state) {
        const item = state['ORDER_DETAIL_READ_DATA']

        return item && item.data && item.data[this.collection]
      }
    }),
  },
  methods: {
    showError(errors) {
      this.$toast.open({
        message: 'Something went wrong with your download request',
        type: 'warning'
      })

      console.error(errors);
    },
    showDownloadDialog(blob) {
      const link = document.createElement("a")
      link.href = URL.createObjectURL(blob)
      link.download = this.filename
      link.id = this.linkId
      document.body.appendChild(link)

      this.loading = false

      MicroModal.show(this.modalUUID);
    },
    async download() {
      this.$toast.open({
        message: 'Your download request has started generating',
        type: 'success'
      })

      this.loading = true

      const zippedBlob = await downloadZip(downloadAssetsGenerator(this.records)).blob()

      this.showDownloadDialog(zippedBlob)
    },
    save() {
      const link = document.getElementById(this.linkId)

      link.click()
      link.remove()

      MicroModal.close(this.modalUUID);
    }
  },
})
</script>
