import axios from 'axios'
import { csrfToken } from '@rails/ujs'

const request = (store, { url, method, params, mutationTypes, data }) => {
  const options = {
    url,
    method,
    data,
    params,
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken()
    }
  }

	store.commit(mutationTypes.PENDING)

	return axios(options).then(response => {
	  store.commit(mutationTypes.SUCCESS, response)
    return response
  }).catch(error => {
    store.commit(mutationTypes.FAILURE, error)
    return error
  })
}

export default {
  get(store, options) {
    return request(store, {method: 'get', ...options})
  },

  post(store, data, options) {
    return request(store, {data, method: 'post', ...options})
  },

  put(store, data, options) {
    return request(store, {data, method: 'put', ...options})
  },

  delete(store, options) {
    return request(store, {method: 'delete', ...options})
  },
}
