<template>
  <order-table :target="target" :collection="collection">
    <template v-slot:header>
      <tr>
        <th style="width:10%;">id</th>
        <th style="width:30%;">Name</th>
        <th style="width:20%;">Status</th>
        <th style="width:10%;">%</th>
        <th style="width:20%;">Created at</th>
        <th style="width:10%;">Actions</th>
      </tr>
    </template>

    <template v-slot:row="child">
      <td>{{ child.record.id }}</td>
      <td>{{ child.record.filename }}</td>
      <td><state-label :name="child.record.state_name" /></td>
      <td>{{ child.record.percent }}</td>
      <td>{{ child.record.created_at }}</td>
      <td><a v-if="child.record.url" class="btn btn-inverse-info btn-sm" target="blank" :href="child.record.url">Download</a></td>
    </template>
  </order-table>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import OrderTable from 'src/widgets/order_table_app'
import StateLabel from 'src/widgets/state_label.vue'

export default defineComponent({
  props: {
    target: {
      type: String
    },
    collection: {
      type: String
    }
  },
  components: {
    OrderTable,
    StateLabel
  }
})
</script>
