<template>
  <div class="show-more-app-container">
    <div ref="detail" :class="{'default': !expanded, 'expanded': expanded}" :style="`--lines: ${lines}`">
      <slot><div v-html="text"></div></slot>
    </div>
    <a
      v-if="!expanded && triggerShowMore"
      class="anchor"
      @click="onClick"
      >{{ moreText }}</a>
    <a
      v-if="expanded"
      class="anchor"
      @click="onClick"
      >{{ lessText }}</a>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    text: {
      type: String
    },
    lines: {
      type: Number,
      default: 3,
    },
    moreText: {
      type: String,
      default: 'Show more',
    },
    lessText: {
      type: String,
      default: 'Show less',
    }
  },
  data() {
    return {
      expanded: false,
      triggerShowMore: false,
    }
  },
  mounted() {
    this.$nextTick(function () {
      this.determineShowMore()
    })
  },
  methods: {
    onClick() {
      this.expanded = !this.expanded
      this.$emit('click', this.expanded);
    },
    determineShowMore() {
      if (
        this.$refs.detail &&
        this.$refs.detail.offsetHeight < this.$refs.detail.scrollHeight
      ) {
        this.triggerShowMore = true
      } else {
        this.triggerShowMore = false
      }
    },
  }
})
</script>
<style scoped>
.show-more-app-container .default{
  --lines: 3;
  white-space: pre-line;
  display: -webkit-box;
  max-width:100%;
  -webkit-line-clamp: var(--lines);
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.show-more-app-container .expanded{
  display: block;
  display: -webkit-box;
  white-space: pre-line;
}
.show-more-app-container .anchor{
  display: block;
  text-align: right;
  padding: 8px;
  margin-bottom: 4px;
  color: #1976d2;
  cursor: pointer;
}
</style>
