import { createStore, createLogger } from 'vuex'
import orders from './modules/orders'
import profiles from './modules/profiles'
import work_schedules from './modules/work_schedules'
import comments from './modules/comments'
import kpi from './modules/kpi'
import tickets from './modules/tickets'
import papers from './modules/papers'

const debug = process.env.NODE_ENV !== 'production'

export default createStore({
  modules: {
    orders,
    profiles,
    work_schedules,
    comments,
    kpi,
    tickets,
    papers
  },
  strict: debug,
  plugins: debug ? [createLogger()] : []
})
