<template>
  <canvas ref="canvas" :width="size" :height="size" />
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const Defaults = {
  colours: ["#1abc9c", "#2ecc71", "#3498db", "#9b59b6", "#34495e", "#16a085", "#27ae60", "#2980b9",
            "#8e44ad", "#2c3e50", "#f1c40f", "#e67e22", "#e74c3c", "#95a5a6", "#f39c12", "#d35400",
            "#c0392b", "#bdc3c7", "#7f8c8d"],
  font: '12px Arial',
  textAlign: 'center',
  textBaseline: 'middle',
  fillStyle: '#FFF',
}

export default defineComponent({
  props: {
    name: {
      type: String
    },
    size: {
      type: Number,
      default: 50
    },
    rect: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.draw()
  },
  methods: {
    draw(){
      const context = this.$refs.canvas.getContext('2d')

      const textSize = this.size / 2;
      const initials = this.extractInitials(this.name)
      const index = this.extractColorIndex(this.name)

      context.fillStyle = Defaults.colours[index];

      if (this.rect) {
        context.fillRect(0, 0, this.size, this.size);
      } else {
        context.arc(textSize, textSize, textSize, 0, 2 * Math.PI);
        context.fill();
      }

      context.font = Defaults.font;
      context.textAlign = Defaults.textAlign;
      context.textBaseline = Defaults.textBaseline;
      context.fillStyle = Defaults.fillStyle;

      context.fillText(initials, textSize, textSize)
    },
    extractInitials(name) {
      const nameSplit = name.replace(/[^a-zA-Z ]/g, '').split(' ')

      if (nameSplit.length == 1) {
        return nameSplit[0].charAt(0).toUpperCase() + nameSplit[0].charAt(1).toUpperCase()
      } else {
        return nameSplit[0].charAt(0).toUpperCase() + nameSplit[1].charAt(0).toUpperCase()
      }
    },

    extractColorIndex(name) {
      const charIndex = name.charCodeAt(0) - 65
      return charIndex % 19
    }
  }
})
</script>
