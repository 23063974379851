<template>
  <span :class="className">{{ name }}</span>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    name: {
      type: String
    },
  },
  computed: {
    className() {
      return `badge badge-${this.name}`
    }
  }
})
</script>
