import { findIndex } from 'lodash-es'

import * as types from 'src/store/mutation-types'
import api from 'src/utils/api'

// initial state
const state = () => ({
  TICKETS_LIST_LOADING: false,
  TICKETS_LIST_DATA: null,

  TICKET_DETAIL_READ_LOADING: false,
  TICKET_DETAIL_READ_DATA: null
})

// getters
const getters = {
  loading: state => {
    return state[types.TICKETS_LIST.loadingKey] || state[types.TICKET_DETAIL_READ.loadingKey]
  },
  submitting: state => {
    return state[types.TICKET_MESSAGE_CREATE.loadingKey]
  },
  list: state => {
    return state[types.TICKETS_LIST.stateKey]
  },
  detail: state => {
    return state[types.TICKET_DETAIL_READ.stateKey]
  },
  error: state => {
    return state[types.TICKETS_LIST.errorKey] ||
      state[types.TICKET_DETAIL_READ.errorKey]
  },
  count: (state, getters) => {
    if (getters.list) {
      return getters.list.length
    } else {
      return 0
    }
  },
}

// actions
const actions = {
  listReadRequest(store, {id}) {
    return api.get(store, {
      url: `/orders/${id}/tickets.json`,
      mutationTypes: types.TICKETS_LIST
    })
  },
  detailReadRequest(store, {order_id, id}) {
    return api.get(store, {
      url: `/orders/${order_id}/tickets/${id}.json`,
      mutationTypes: types.TICKET_DETAIL_READ
    })
  },

  createMessageRequest(store, {order_id, id, message}) {
    const data = {
      message: { content: message, ticket_id: id }
    }

    return api.post(store, data, {
      url: `/orders/${order_id}/tickets/${id}/messages.json`,
      mutationTypes: types.TICKET_MESSAGE_CREATE
    })
  },
}

// mutations
const mutations = {
  [types.TICKETS_LIST.PENDING] (state) {
    state[types.TICKETS_LIST.loadingKey] = true
	},
  [types.TICKETS_LIST.SUCCESS] (state, {data}) {
		state[types.TICKETS_LIST.loadingKey] = false
    state[types.TICKETS_LIST.stateKey] = data.data
	},
  [types.TICKETS_LIST.FAILURE] (state, error) {
    state[types.TICKETS_LIST.loadingKey] = false
    state[types.TICKETS_LIST.errorKey] = error
	},

  [types.TICKET_DETAIL_READ.PENDING] (state) {
    state[types.TICKET_DETAIL_READ.loadingKey] = true
	},
  [types.TICKET_DETAIL_READ.SUCCESS] (state, {data}) {
		state[types.TICKET_DETAIL_READ.loadingKey] = false
    state[types.TICKET_DETAIL_READ.stateKey] = data.data
	},
  [types.TICKET_DETAIL_READ.FAILURE] (state) {
    state[types.TICKET_DETAIL_READ.loadingKey] = false
    state[types.TICKET_DETAIL_READ.stateKey] = null
	},

  // Create
  [types.TICKET_MESSAGE_CREATE.PENDING] (state) {
    state[types.TICKET_MESSAGE_CREATE.loadingKey] = true
	},
  [types.TICKET_MESSAGE_CREATE.SUCCESS] (state, {data}) {
    const message = data.data

		state[types.TICKET_MESSAGE_CREATE.loadingKey] = false
    state[types.TICKET_MESSAGE_CREATE.stateKey] = message

    // Add new message to current ticket's messages
    state[types.TICKET_DETAIL_READ.stateKey].messages.unshift(message)
	},
  [types.TICKET_MESSAGE_CREATE.FAILURE] (state, error) {
    state[types.TICKET_MESSAGE_CREATE.loadingKey] = false
    state[types.TICKET_MESSAGE_CREATE.errorKey] = error
	},
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
