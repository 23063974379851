import { findIndex } from 'lodash-es'

import * as types from 'src/store/mutation-types'
import api from 'src/utils/api'
import { genRandomUUID } from 'src/utils/common'

// initial state
const state = () => ({
  COMMENTS_LIST_LOADING: false,
  COMMENTS_LIST_DATA: null
})

// getters
const getters = {
  loading: state => {
    return state[types.COMMENTS_LIST.loadingKey]
  },
  list: state => {
    return state[types.COMMENTS_LIST.stateKey]
  },
  error: state => {
    return state[types.COMMENTS_LIST.errorKey] ||
      state[types.COMMENTS_CREATE.errorKey] ||
      state[types.COMMENTS_DELETE.errorKey]
  },
  count: (state, getters) => {
    if (getters.list) {
      return getters.list.length
    } else {
      return 0
    }
  },
}

// actions
const actions = {
  listReadRequest(store, data) {
    return api.get(store, {
      url: '/comments.json',
      params: data,
      mutationTypes: types.COMMENTS_LIST
    })
  },
  createRequest(store, data) {
    const user = store.rootGetters['profiles/currentUser']
    const uuid = genRandomUUID()

    const comment = {
      id: uuid,
      direction: 'outgoing',
      author_name: user.name,
      author_avatar_url: user.avatar_url,
      uuid,
      ...data
    }

    store.commit('addPendingComment', comment)

    return api.post(store, {comment}, {
      url: '/comments.json',
      mutationTypes: types.COMMENTS_CREATE
    })
  },
  deleteRequest(store, id) {
    store.commit('deleteComment', id)

    return api.delete(store, {
      url: `/comments/${id}.json`,
      mutationTypes: types.COMMENTS_DELETE
    })
  }
}

function updateResource(list, name, data) {
  const index = findIndex(list, [name, data[name]])

  if (index < 0) {
    return list
  }

  return [
    ...list.slice(0, index),
    { ...list[index], ...data },
    ...list.slice(index + 1),
  ]
}

function deleteResource(list, name, data) {
  const index = findIndex(list, [name, data[name]])

  if (index < 0) {
    return list
  }

  return [
    ...list.slice(0, index),
    ...list.slice(index + 1),
  ]
}

// mutations
const mutations = {
  [types.COMMENTS_LIST.PENDING] (state) {
    state[types.COMMENTS_LIST.loadingKey] = true
	},
  [types.COMMENTS_LIST.SUCCESS] (state, {data}) {
		state[types.COMMENTS_LIST.loadingKey] = false
    state[types.COMMENTS_LIST.stateKey] = data.data
	},
  [types.COMMENTS_LIST.FAILURE] (state, error) {
    state[types.COMMENTS_LIST.loadingKey] = false
    state[types.COMMENTS_LIST.errorKey] = error
	},

  // Create
  [types.COMMENTS_CREATE.PENDING] (state) {
    state[types.COMMENTS_CREATE.loadingKey] = true
	},
  [types.COMMENTS_CREATE.SUCCESS] (state, {data}) {
		state[types.COMMENTS_CREATE.loadingKey] = false
    state[types.COMMENTS_CREATE.stateKey] = data.data

    const list = updateResource(state[types.COMMENTS_LIST.stateKey], 'uuid', data.data)

    state[types.COMMENTS_LIST.stateKey] = list
	},
  [types.COMMENTS_CREATE.FAILURE] (state, error) {
    state[types.COMMENTS_CREATE.loadingKey] = false
    state[types.COMMENTS_CREATE.errorKey] = error
	},

  // Delete
  [types.COMMENTS_DELETE.PENDING] (state) {
    state[types.COMMENTS_DELETE.loadingKey] = true
	},
  [types.COMMENTS_DELETE.SUCCESS] (state, {data}) {
		state[types.COMMENTS_DELETE.loadingKey] = false
    state[types.COMMENTS_DELETE.stateKey] = data.data

    const list = deleteResource(state[types.COMMENTS_LIST.stateKey], 'id', data.data)

    state[types.COMMENTS_LIST.stateKey] = list
	},
  [types.COMMENTS_DELETE.FAILURE] (state, error) {
    state[types.COMMENTS_DELETE.loadingKey] = false
    state[types.COMMENTS_DELETE.errorKey] = error
	},

  // Custom
  addPendingComment(state, comment) {
    state[types.COMMENTS_LIST.stateKey].push(comment)
  },

  deleteComment(state, id) {
    const data = {
      deleting: true,
      id
    }

    const list = updateResource(state[types.COMMENTS_LIST.stateKey], 'id', data)

    state[types.COMMENTS_LIST.stateKey] = list
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
