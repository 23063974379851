import * as types from 'src/store/mutation-types'
import api from 'src/utils/api'

// initial state
const state = () => ({
  PROFILE_DETAIL_READ_LOADING: false,
  PROFILE_DETAIL_READ_DATA: null
})

// getters
const getters = {
  currentUser: state => {
    return state[types.PROFILE_DETAIL_READ.stateKey]
  },
  loading: state => {
    return state[types.PROFILE_DETAIL_READ.loadingKey] || state[types.PROFILE_UPDATE.loadingKey]
  },
  error: state => {
    return state[types.PROFILE_DETAIL_READ.errorKey] || state[types.PROFILE_UPDATE.errorKey]
  },
  updated: state => {
    return state[types.PROFILE_UPDATE.stateKey]
  }
}

// actions
const actions = {
  detailReadRequest(store) {
    return api.get(store, {url: `/account/profile.json`, mutationTypes: types.PROFILE_DETAIL_READ})
  },
  updateRequest(store, data) {
    return api.put(store, data, {url: `/account/profile.json`, mutationTypes: types.PROFILE_UPDATE})
  },
  documentsReadRequest(store) {
    return api.get(store, {url: `/account/documents.json`, mutationTypes: types.PROFILE_DOCUMENTS})
  }
}

// mutations
const mutations = {
  // profile read
  [types.PROFILE_DETAIL_READ.PENDING] (state) {
    state[types.PROFILE_DETAIL_READ.loadingKey] = true
	},
  [types.PROFILE_DETAIL_READ.SUCCESS] (state, {data}) {
		state[types.PROFILE_DETAIL_READ.loadingKey] = false
    state[types.PROFILE_DETAIL_READ.stateKey] = data.data
	},
  [types.PROFILE_DETAIL_READ.FAILURE] (state) {
    state[types.PROFILE_DETAIL_READ.loadingKey] = false
    state[types.PROFILE_DETAIL_READ.stateKey] = null
	},

  // profile update
  [types.PROFILE_UPDATE.PENDING] (state) {
    state[types.PROFILE_UPDATE.loadingKey] = true
	},
  [types.PROFILE_UPDATE.SUCCESS] (state, {data}) {
		state[types.PROFILE_UPDATE.loadingKey] = false
    state[types.PROFILE_UPDATE.stateKey] = data
	},
  [types.PROFILE_UPDATE.FAILURE] (state) {
    state[types.PROFILE_UPDATE.loadingKey] = false
    state[types.PROFILE_UPDATE.stateKey] = null
	},
  // Documents
  [types.PROFILE_DOCUMENTS.PENDING] (state) {
    state[types.PROFILE_DOCUMENTS.loadingKey] = true
	},
  [types.PROFILE_DOCUMENTS.SUCCESS] (state, {data}) {
		state[types.PROFILE_DOCUMENTS.loadingKey] = false
    state[types.PROFILE_DOCUMENTS.stateKey] = data
	},
  [types.PROFILE_DOCUMENTS.FAILURE] (state) {
    state[types.PROFILE_DOCUMENTS.loadingKey] = false
    state[types.PROFILE_DOCUMENTS.stateKey] = null
	}
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
