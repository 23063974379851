import Pusher from 'pusher-js'
import { csrfToken } from '@rails/ujs'

function pusherKey() {
  const meta = document.querySelector('meta[name=pusher-key]')
  return meta && meta.content;
}

function pusherHost() {
  const meta = document.querySelector('meta[name=pusher-host]')
  return meta && meta.content;
}

function pusherCluster(){
  const host = pusherHost()

  if (host) {
    return host.split('-')[1].substring(0, 2)
  }
}

function initPusher() {
  const key = pusherKey()

  Pusher.logToConsole = false

  if (key) {
    return new Pusher(key, {
      cluster: pusherCluster(),
      authEndpoint: '/auth/pusher',
      auth: {
        headers: {
          'X-CSRF-Token': csrfToken()
        }
      }
    })
  }
}

export default {
  build: initPusher
}
