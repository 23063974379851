import * as types from 'src/store/mutation-types'
import api from 'src/utils/api'

// initial state
const state = () => ({
  PAPER_UPDATE_LOADING: false,
  PAPER_UPDATE_DATA: null
})

// getters
const getters = {
  submitting: state => {
    return state[types.PAPER_UPDATE.loadingKey]
  },
  error: state => {
    return state[types.PAPER_UPDATE.errorKey]
  },
  updated: state => {
    return state[types.PAPER_UPDATE.stateKey]
  }
}

// actions
const actions = {
  updateRequest(store, data) {
    const { order_id, id, ...params } = data;

    return api.put(
      store,
      {paper: params},
      {
        url: `/orders/${order_id}/papers/${id}.json`,
        mutationTypes: types.PAPER_UPDATE
      }
    );
  },
}

// mutations
const mutations = {
  // profile update
  [types.PAPER_UPDATE.PENDING] (state) {
    state[types.PAPER_UPDATE.loadingKey] = true
	},
  [types.PAPER_UPDATE.SUCCESS] (state, {data}) {
		state[types.PAPER_UPDATE.loadingKey] = false
    state[types.PAPER_UPDATE.stateKey] = data
	},
  [types.PAPER_UPDATE.FAILURE] (state) {
    state[types.PAPER_UPDATE.loadingKey] = false
    state[types.PAPER_UPDATE.stateKey] = null
	},
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
