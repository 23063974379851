import TurbolinksAdapter from 'vue-turbolinks'
import { createApp } from 'vue'
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'

import store from 'src/store'
import PusherClient from 'src/utils/pusher_client'
import ProfileShowApp from 'src/widgets/profile_show_app'
import PageViewersApp from 'src/widgets/page_viewers_app'
import UploaderApp from 'src/widgets/uploader_app'
import OrderShowApp from 'src/widgets/order_show_app'
import OrderChatApp from 'src/widgets/order_chat_app'
import AssetsTableApp from 'src/widgets/assets_table_app'
import PapersTableApp from 'src/widgets/papers_table_app'
import RevisionPapersTableApp from 'src/widgets/revision_papers_table_app'
import FeaturesTableApp from 'src/widgets/features_table_app'
import DraftsTableApp from 'src/widgets/drafts_table_app'
import PlagiarismTableApp from 'src/widgets/plagiarism_table_app'
import RatingsTableApp from 'src/widgets/ratings_table_app'
import WorkSchedulesApp from 'src/widgets/work_schedules_app'
import WorkSchedulesLabelApp from 'src/widgets/work_schedules_label_app'
import WorkSchedulesButtonApp from 'src/widgets/work_schedules_button_app'
import ToastProviderApp from 'src/widgets/toast_provider_app'
import AvatarUploaderApp from 'src/widgets/avatar_uploader_app'
import DocumentUploaderApp from 'src/widgets/document_uploader_app'
import OrderLabelApp from 'src/widgets/order_label_app'
import DocumentsTableApp from 'src/widgets/documents_table_app'
import CommentsApp from 'src/widgets/comments_app'
import WorkSchedulesCommentsApp from 'src/widgets/work_schedules_comments_app.vue'
import ShowMoreApp from 'src/widgets/show_more_app.vue'
import TicketsTableApp from 'src/widgets/tickets_table_app.vue'
import TicketShowApp from 'src/widgets/ticket_show_app.vue'

const toastConfig = {
  position: 'top-right',
  duration: 7000,
  dismissible: true
}

function mountApp(app, element) {
  app.use(store)
  app.use(TurbolinksAdapter)
  app.use(VueToast, toastConfig)

  app.mount(element)

  return app
}

function initPageViewersApp(pusher) {
  const element = document.getElementById('page-viewers-app')

  if (element != null) {
    const app = createApp(PageViewersApp, {
      client: pusher,
      ...element.dataset
    })
    app.use(TurbolinksAdapter)
    app.mount(element)

    return app
  }
}

function initUploaderApp(cssName) {
  const elements = document.getElementsByClassName(cssName)
  let app = null

  Array.prototype.forEach.call(elements, (element) => {
    app = createApp(UploaderApp, {params: element.dataset})
    mountApp(app, element)
  })
}

function initElementApp(dom_id, klass) {
  const element = document.getElementById(dom_id)

  if (element) {
    const app = createApp(klass, element.dataset)
    mountApp(app, element)
  }
}

function initElementsApp(cssName, klass) {
  const elements = document.getElementsByClassName(cssName)
  let app = null

  Array.prototype.forEach.call(elements, (element) => {
    app = createApp(klass, element.dataset)
    mountApp(app, element)
  })
}

function initShowMoreApps(cssName, klass) {
  const elements = document.getElementsByClassName(cssName)
  let app = null

  Array.prototype.forEach.call(elements, (element) => {
    let options = {text: element.innerHTML, ...element.dataset}
    app = createApp(klass, options)
    mountApp(app, element)
  })
}

function initOrderTableApp(cssName, klass) {
  const elements = document.getElementsByClassName(cssName)
  let app = null

  Array.prototype.forEach.call(elements, (element) => {
    app = createApp(klass, element.dataset)
    mountApp(app, element)
  })
}

export default {
  start: function() {
    const pusher = PusherClient.build()

    document.addEventListener('turbolinks:load', () => {
      initPageViewersApp(pusher)
      initElementApp('profile-show-app', ProfileShowApp)
      initElementApp('order-show-app', OrderShowApp)
      initElementApp('order-chat-app', OrderChatApp)
      initElementApp('work-schedules-app', WorkSchedulesApp)
      initElementApp('work-schedules-button-app', WorkSchedulesButtonApp)
      initElementApp('work-schedules-comments-app', WorkSchedulesCommentsApp)
      initElementApp('toast-provider', ToastProviderApp)
      initElementApp('avatar-uploader-app', AvatarUploaderApp)
      initElementApp('profile-documents-table-app', DocumentsTableApp)
      initElementApp('profile-documents-table-app', DocumentsTableApp)
      initElementApp('ticket-show-app', TicketShowApp)

      initOrderTableApp('assets-table-app', AssetsTableApp)
      initOrderTableApp('features-table-app', FeaturesTableApp)
      initOrderTableApp('drafts-table-app', DraftsTableApp)
      initOrderTableApp('plagiarism-table-app', PlagiarismTableApp)
      initOrderTableApp('ratings-table-app', RatingsTableApp)
      initOrderTableApp('papers-table-app', PapersTableApp)
      initOrderTableApp('revision-papers-table-app', RevisionPapersTableApp)

      initElementsApp('work-schedules-label-app', WorkSchedulesLabelApp)
      initElementsApp('document-uploader-app', DocumentUploaderApp)
      initElementsApp('order-label', OrderLabelApp)
      initElementsApp('comments-app', CommentsApp)
      initElementsApp('tickets-table-app', TicketsTableApp)

      initShowMoreApps('shop-more-app', ShowMoreApp)
    })
  }
}
