<template>
  <div>
    <div v-if="loading">
      <Preloader />
    </div>
    <div class="table-responsive" v-else>
      <table class="table table-bordered table-hover">
        <thead>
          <slot name="header">
            <tr>
              <th scope="col">Type</th>
              <th scope="col">Filename</th>
              <th scope="col">Size</th>
              <th scope="col">Created at</th>
              <th scope="col">Actions</th>
            </tr>
          </slot>
        </thead>
        <tbody>
          <tr v-for="record in records" :key="record.id">
            <slot name="row" :record="record">
              <td>{{ record.type_title }}</td>
              <td>{{ record.filename }}</td>
              <td>{{ record.human_size }}</td>
              <td>{{ record.created_at }}</td>
              <td>
                <a v-if="record.url" class="btn btn-outline-primary btn-sm" target="blank" :href="record.url">Download</a>
              </td>
            </slot>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState } from 'vuex'

import Preloader from 'src/components/preloader.vue'

export default defineComponent({
  props: {
    target: {
      type: String
    }
  },
  components: {
    Preloader
  },
  computed: {
    ...mapState('profiles', {
      loading(state) {
        return state['PROFILE_DOCUMENTS_LOADING']
      },
      records(state) {
        const item = state['PROFILE_DOCUMENTS_DATA']

        return item && item.data
      }
    }),
  },
  created() {
    this.$store.dispatch('profiles/documentsReadRequest')
	}
})
</script>
