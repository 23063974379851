<template>
  <order-table :target="target" :collection="collection">
    <template v-slot:header>
      <tr>
        <th>Writer ID</th>
        <th>Instructions</th>
        <th>Grammar</th>
        <th>Plagiarism</th>
        <th>Formatting</th>
        <th>Order ranking</th>
        <th>Filename</th>
      </tr>
    </template>

    <template v-slot:row="child">
      <td>{{ child.record.writer_id }}</td>
      <td>{{ renderVoteValue(child.record.votes, 'instructions') }}</td>
      <td>{{ renderVoteValue(child.record.votes, 'grammar') }}</td>
      <td>{{ renderVoteValue(child.record.votes, 'plagiarism') }}</td>
      <td>{{ renderVoteValue(child.record.votes, 'formatting') }}</td>
      <td>{{ child.record.moderator_total }}</td>
      <td>
        {{ child.record.asset && child.record.asset.filename }}
      </td>
    </template>
  </order-table>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import OrderTable from 'src/widgets/order_table_app'
import { filter } from 'lodash-es';

export default defineComponent({
  props: {
    target: {
      type: String
    },
    collection: {
      type: String
    },
    vote_types: {
      type: Object,
      default: {
        instructions: 1,
        format: 2,
        grammar: 3,
        plagiarism: 4,
        content: 5,
        formatting: 6
      }
    }
  },
  components: {
    OrderTable
  },
  methods: {
    renderVoteValue(votes, vote_type) {
      const vote_type_id = this.vote_types[vote_type]
      const vote = filter(votes, { 'vote_type_id': vote_type_id })[0]

      return vote && vote['value']
    },
    renderRateLink(rating_id) {
      return `/orders/${this.target}/ratings/${rating_id}/edit`
    }
  }
})
</script>
