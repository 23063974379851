<template>
  <div class="current_user"></div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  created() {
    this.$store.dispatch('profiles/detailReadRequest')
	}
})
</script>
