<template>
  <slot :resize="resize"></slot>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    mounted() {
      this.$nextTick(this.update)
    },
    methods: {
      resize(event) {
        event.target.style.height = "auto";
        event.target.style.height = `${event.target.scrollHeight}px`;
      },
      update() {
        const element = this.$el.nextElementSibling

        element.style.height = "auto";
      }
    }
  })
</script>
