<template>
  <div class="bar-loader">
    <span v-for="n in size" :key="n"></span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    size: {
      type: Number,
      default: 4
    },
  }
})
</script>
