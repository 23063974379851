<template>
  <button
    :disabled="loading"
    @click="onClick"
    class="btn btn-block btn-lg btn-gradient-success">
    {{ content }}
  </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'

export default defineComponent({
  props: {
    name: {
      type: String
    },
  },
  computed: {
    content() {
      return this.loading ? 'Loading...' : this.name
    },
    ...mapGetters('work_schedules', [
      'loading'
    ])
  },
  methods: {
    onClick() {
      this.update().then(({ data }) => {
        if (data && data['success']) {
          this.$toast.open({
            message: 'Calendar was successfully updated.',
            type: 'success'
          })
        }
      })
    },
    ...mapActions('work_schedules', [
      'update'
    ])
  }
})
</script>
