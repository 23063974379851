import CyrillicToTranslit from 'cyrillic-to-translit-js';

export const genRandomUUID = () => {
  const s4 = ()=> Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
  return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4() + s4() + s4()}`;
}

export const stripHTML = (html) => {
  if (html) {
    return html.toString().replace(/<(?:.|\n)*?>/gm, '')
  }
}

export const isMobile = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
}

export const download = url => {
  const options = {
    mode: 'no-cors',
    credentials: 'include'
  }

  return fetch(url, options).then(response => response.blob());
};

export const downloadMany = urls => {
  return Promise.all(urls.map(url => download(url)));
}

export const downloadAsset = asset => {
  const options = {
    mode: 'no-cors',
    credentials: 'include'
  }

  return fetch(asset.url, options).then((response) => {
    return {
      name: asset.filename,
      lastModified: new Date(),
      input: response
    }
  });
};

export const downloadAssets = assets => {
  return Promise.all(assets.map(asset => downloadAsset(asset)));
}

export async function* downloadAssetsGenerator(assets) {
  const sanitizer = new CyrillicToTranslit({ preset: 'uk' });
  const options = {
    redirect: 'follow'
  }

  for(let i = 0; i < assets.length; i++) {
    const asset = assets[i]
    const filename = sanitizer.transform(asset.filename, '_').toLowerCase();
    const name = (i + 1) + '_' + filename

    yield { input: await fetch(asset.url, options), name: name }
  }
}
