<template>
  <uploader-app
    :config="config"
    :dashboard="dashboard"
    :params="params"
    :onBodyBuilder="onBodyBuilder"
    :onComplete="onComplete">
    <template v-slot:button="slotProps">
      <div class="btn-group">
        <button class="btn btn-outline-primary dropdown-toggle" data-toggle="dropdown" type="button">{{content}}</button>
        <div class="dropdown-menu">
          <a v-for="record in records"
            :key="record.id"
            class="dropdown-item"
            @click.stop.prevent="onRecordClick(record, slotProps.onClick, $event)"
            href="#uploader">
            {{record.title}}
          </a>
        </div>
      </div>
    </template>
  </uploader-app>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import UploaderApp from 'src/widgets/uploader_app'

export default defineComponent({
  props: {
    content: {
      type: String
    },
    targets: {
      type: String
    },
    target: {
      type: String
    },
    type: {
      type: String,
      default: 'RemoteQa'
    }
  },
  data() {
    return {
      records: JSON.parse(this.targets),
      record: null
    }
  },
  components: {
    UploaderApp
  },
  computed: {
    config() {
      return {
        autoProceed: false,
        restrictions: {
          maxTotalFileSize: 50_000_000,
          maxNumberOfFiles: 10,
          minNumberOfFiles: 1,
          allowedFileTypes: ['image/*', '.doc', '.docx', '.pdf'],
        },
      }
    },
    dashboard() {
      return {
        theme: 'light',
        inline: false,
        closeModalOnClickOutside: true,
        disablePageScrollWhenModalOpen: false,
        allowMultipleUploads: true,
        closeAfterFinish: true,
      }
    },
    params() {
      return {
        target: this.target,
        record: this.record,
        type: this.type
      }
    },
  },
  methods: {
    onBodyBuilder(file, params) {
      return {
        filename: file.name,
        type: file.type,
        size: file.size,
        provider: 's3',
        klass: params.record.klass,
        assetable_id: params.target,
        assetable_type: params.type
      }
    },
    onRecordClick(record, callback, event){
      this.record = record
      callback.call(null, event)
    },
    onComplete(result, uppy) {
      // show notification
      this.$toast.open({
        message: `The files ${this.record.title} was successfully uploaded.`,
        type: 'success'
      })

      // reload documents table
      this.$store.dispatch('profiles/documentsReadRequest')

      // reset uploader state
      window.setTimeout(() => {
        uppy.reset()
      }, 500)
    }
  }
})
</script>

<style>
a.upload-document {
  text-decoration: none;
  color: #212529;
}
</style>
