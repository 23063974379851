<template>
  <order-table :target="target" :collection="collection">
    <template v-slot:header>
      <tr>
        <th style="width:40%;">Filename</th>
        <th style="width:10%;">Size</th>
        <th style="width:20%;">Created by</th>
        <th style="width:20%;">Created at</th>
        <th style="width:10%;">Actions</th>
      </tr>
    </template>

    <template v-slot:row="child">
      <td>
        {{ child.record.filename }}
        <state-label :name="child.record.state_name" />
      </td>
      <td>{{ child.record.human_size }}</td>
      <td>{{ child.record.user_role }}</td>
      <td>{{ child.record.created_at }}</td>
      <td>
        <a v-if="child.record.url" class="btn btn-outline-primary btn-sm" target="blank" :href="child.record.url">Download</a>
      </td>
    </template>
  </order-table>

  <div class="mt-4" v-if="canUpdate">
    <uploader-app :params="uploaderParams" :onUpload="onUpload" />
  </div>

</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { format } from 'date-fns'
import { mapMutations, mapGetters } from 'vuex'

import OrderTable from 'src/widgets/order_table_app'
import UploaderApp from 'src/widgets/uploader_app'
import StateLabel from 'src/widgets/state_label'

export default defineComponent({
  props: {
    target: {
      type: String
    },
    collection: {
      type: String
    },
    klass: {
      type: String
    }
  },
  components: {
    OrderTable,
    UploaderApp,
    StateLabel
  },
  computed: {
    uploaderParams() {
      return {
        target: this.target,
        klass: this.klass
      }
    },
    ...mapGetters('orders', {
      canDownload: 'canDownload',
      canUpdate: 'canUpdate'
    })
  },
  methods: {
    ...mapMutations('orders', [
      'createRecord'
    ]),
    onUpload(file, data) {
      const record = this.buildAsset(file, data)
      this.createRecord({association: this.collection, record})
    },
    buildAsset(file, data) {
      const id = file.meta['x-amz-meta-asset-id'] || file.id;

      return {
        id: id,
        byte_size: file.size,
        created_at: format(new Date(), 'dd MMM HH:mm'),
        filename: file.name,
        human_size: file.size,
        url: data.uploadURL
      }
    }
  }
})
</script>
