
<template>
  <div class="loadingio-spinner-dual-ball-ididsf47v7">
    <div class="ldio-ijqi67i0lxp">
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    size: {
      type: Number,
      default: 4
    },
  }
})
</script>

<style type="text/css">
@keyframes ldio-ijqi67i0lxp-o {
    0%    { opacity: 1; transform: translate(0 0) }
   49.99% { opacity: 1; transform: translate(28px,0) }
   50%    { opacity: 0; transform: translate(28px,0) }
  100%    { opacity: 0; transform: translate(0,0) }
}
@keyframes ldio-ijqi67i0lxp {
    0% { transform: translate(0,0) }
   50% { transform: translate(28px,0) }
  100% { transform: translate(0,0) }
}
.ldio-ijqi67i0lxp div {
  position: absolute;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  top: 36px;
  left: 22px;
}
.ldio-ijqi67i0lxp div:nth-child(1) {
  background: #fe7c96;
  animation: ldio-ijqi67i0lxp 1s linear infinite;
  animation-delay: -0.5s;
}
.ldio-ijqi67i0lxp div:nth-child(2) {
  background: #b66dff;
  animation: ldio-ijqi67i0lxp 1s linear infinite;
  animation-delay: 0s;
}
.ldio-ijqi67i0lxp div:nth-child(3) {
  background: #fe7c96;
  animation: ldio-ijqi67i0lxp-o 1s linear infinite;
  animation-delay: -0.5s;
}
.loadingio-spinner-dual-ball-ididsf47v7 {
  width: 41px;
  height: 41px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-ijqi67i0lxp {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.41);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-ijqi67i0lxp div { box-sizing: content-box; }
</style>
