<template>
  <div v-if="count > 1" class="page-views-app">
    <p>{{ count }} users are viewing this page</p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    client: {
      type: Object,
      required: true
    },
    target: {
      type: String
    }
  },
  data: function () {
    return {
      count: 0,
      channelName: `presence-pageviewsapp-${this.target}`
    }
  },
  created() {
    this.count = this.subscribe()
  },
  beforeUnmount() {
    this.unsubscribe()
  },
  methods: {
    subscribe() {
      const channel = this.client.subscribe(this.channelName)
      let value = null

      channel.bind('pusher:subscription_succeeded', () => {
        value = channel.members.count
        this.update(value)
      })

      return channel.members.count;
    },
    unsubscribe() {
      this.client.unsubscribe(this.channelName)
    },
    update(value) {
      this.count = parseInt(value, 10)
    }
  }
})
</script>

<style>
  .page-views-app {
    color: #f44a4a;
  }
</style>
