<template>
  <div class="final-paper">
    <span v-if="submitting"><Dualball /></span>

    <div class="form-check form-check-flat form-check-primary" v-else>
      <label class="form-check-label">
        <input type="checkbox" class="form-check-input optional" v-model="checked" v-on:change="update()"/>
        <i class="input-helper"></i>
      </label>

      <span class="ml30">
        <StateLabel :name="label" />
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'

import Dualball from 'src/components/dualball.vue'
import StateLabel from 'src/widgets/state_label.vue'

export default defineComponent({
  props: {
    target: {
      type: String
    },
    asset_id: {
      type: String
    },
    value: {
      type: Boolean,
      default: false
    }
  },
  components: {
    StateLabel,
    Dualball
  },
  data() {
    return {
      checked: this.value
    }
  },
  computed: {
    label() {
      return this.checked === true ? 'yes' : 'no';
    },
    ...mapGetters('papers', {
      submitting: 'submitting',
      updated: 'paper',
    })
  },
  methods: {
    update() {
      const data = {order_id: this.target, id: this.asset_id, final_paper: this.checked}

      this.updateRequest(data).then(({data}) => {
        if (data && data['data']) {
          this.$toast.open({
            message: 'Paper was successfully updated.',
            type: 'success'
          });
        } else {
          this.checked = !this.checked;
          this.$toast.open({
            message: 'Some errors occurred while updating then paper. Please try again later.',
            type: 'error'
          });
        }
      })
    },
    ...mapActions('papers', [
      'updateRequest'
    ])
  }
})
</script>
