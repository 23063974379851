<template>
  <div></div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    target: {
      type: String
    }
  },
  created() {
    this.$store.dispatch('orders/detailReadRequest', {id: this.target})
	}
})
</script>
