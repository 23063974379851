<template>
  <div v-if="enabled">
    <CommentsApp :target="target" :uuid="uuid" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'

import CommentsApp from 'src/widgets/comments_app'

export default defineComponent({
  props: {
    target: {
      type: String,
      default: 'rqa_work_schedule_id'
    }
  },
  components: {
    CommentsApp
  },
  data() {
    return {
      uuid: null
    }
  },
  computed: {
    enabled() {
      return !this.loadingDetail && this.uuid
    },
    ...mapGetters('work_schedules', [
      'getCurrentPage',
      'loadingDetail'
    ])
  },
  watch: {
    getCurrentPage(page) {
      this.load(page)
    }
  },
  created() {
    const page = this.getCurrentPage

    this.load(page)
	},
  methods: {
    load(params) {
      this.uuid = null

      this.detailReadRequest(params).then(({data}) => {
        if (data && data['data'] && data['data']['remote_id']) {
          this.uuid = data['data']['remote_id'].toString()
        }
      })
    },
    ...mapActions('work_schedules', [
      'detailReadRequest'
    ])
  }
})
</script>
