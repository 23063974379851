<template></template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    notice: {
      type: String
    },
    alert: {
      type: String
    },
  },
  created() {
    let data = null

    if (this.notice) {
      data = {message: this.notice, type: 'success'}
    }

    if (this.alert) {
      data = {message: this.alert, type: 'error'}
    }

    if (data && data.message) {
      this.$toast.open(data)
    }
	}
})
</script>
