const createAsyncMutation = (type) => ({
	SUCCESS: `${type}_SUCCESS`,
	FAILURE: `${type}_FAILURE`,
	PENDING: `${type}_PENDING`,
	// just store for key names
	loadingKey: `${type}_LOADING`,
	stateKey: `${type}_DATA`,
	errorKey: `${type}_ERROR`
})

export const ORDER_DETAIL_READ = createAsyncMutation('ORDER_DETAIL_READ')

export const WORK_SCHEDULES_LIST = createAsyncMutation('WORK_SCHEDULES_LIST')
export const WORK_SCHEDULES_DETAIL = createAsyncMutation('WORK_SCHEDULES_DETAIL')
export const WORK_SCHEDULES_SAVE = createAsyncMutation('WORK_SCHEDULES_SAVE')
export const KPI_STATS = createAsyncMutation('KPI_STATS')

export const PROFILE_DETAIL_READ = createAsyncMutation('PROFILE_DETAIL_READ')
export const PROFILE_UPDATE = createAsyncMutation('PROFILE_UPDATE')
export const PROFILE_DOCUMENTS = createAsyncMutation('PROFILE_DOCUMENTS')

// Comments CRUD
export const COMMENTS_LIST = createAsyncMutation('COMMENTS_LIST')
export const COMMENTS_CREATE = createAsyncMutation('COMMENTS_CREATE')
export const COMMENTS_UPDATE = createAsyncMutation('COMMENTS_UPDATE')
export const COMMENTS_DELETE = createAsyncMutation('COMMENTS_DELETE')

// Tickets
export const TICKETS_LIST = createAsyncMutation('TICKETS_LIST')
export const TICKET_DETAIL_READ = createAsyncMutation('TICKET_DETAIL_READ')
export const TICKET_MESSAGE_CREATE = createAsyncMutation('TICKET_MESSAGE_CREATE')

// Papers
export const PAPER_UPDATE = createAsyncMutation('PAPER_UPDATE')
