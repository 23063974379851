import { forIn, sumBy } from 'lodash-es'

import * as types from 'src/store/mutation-types'
import api from 'src/utils/api'

const ENDPOINT_URL = '/work_schedules.json'

// initial state
const state = () => ({
  WORK_SCHEDULES_LIST_LOADING: false,
  WORK_SCHEDULES_LIST_DATA: {},
  WORK_SCHEDULES_LIST_ERROR: null,
  WORK_SCHEDULES_SAVE_LOADING: false,
  WORK_SCHEDULES_SAVE_DATA: {},
  WORK_SCHEDULES_SAVE_ERROR: null,
  WORK_SCHEDULES_CURRENT_PAGE: null
})

function buildKey(date) {
  if (date && date.year && date.month) {
    return `${date.year}_${date.month}`
  } else {
    return null
  }
}

// getters
const getters = {
  getListByDate: state => date => {
    const key = buildKey(date)

    return state[types.WORK_SCHEDULES_LIST.stateKey][key]
  },

  getCurrentPage: state => {
    return state['WORK_SCHEDULES_CURRENT_PAGE']
  },

  getCurrentList: (state, getters) => {
    const key = buildKey(getters.getCurrentPage)

    return state[types.WORK_SCHEDULES_LIST.stateKey][key]
  },

  getIndexInCurrentList: (state, getters) => (day) => {
    const data = getters.getCurrentList
    return data.findIndex(d => d.id === day.id)
  },

  getCurrentListSize: (state, getters) => {
    const data = getters.getCurrentList

    if (data) {
      return data.length
    } else {
      return 0
    }
  },

  getCompletedOrdersCount: (state, getters, rootState, rootGetters) => {
    return rootGetters['kpi/getCompletedOrdersCount']
  },

  getCurrentStats: (state, getters) => (name) => {
    switch(name) {
      case 'ordersCount':
        return getters.getCompletedOrdersCount
      case 'shiftsCount':
        return getters.getCurrentListSize
      default:
        return 0
    }
  },

  loading: state => {
    return state[types.WORK_SCHEDULES_LIST.loadingKey] || state[types.WORK_SCHEDULES_SAVE.loadingKey]
  },

  loadingDetail: state => {
    return state[types.WORK_SCHEDULES_DETAIL.loadingKey]
  },

  error: state => {
    return state[types.WORK_SCHEDULES_LIST.errorKey] || state[types.WORK_SCHEDULES_SAVE.errorKey]
  },

  updated: state => {
    return state[types.WORK_SCHEDULES_SAVE.stateKey]
  }
}

// actions
const actions = {
  listReadRequest(store, data) {
    return api.get(store, {
      url: ENDPOINT_URL,
      params: data,
      mutationTypes: types.WORK_SCHEDULES_LIST
    })
  },

  detailReadRequest(store, {year, month}) {
    return api.get(store, {
      url: `/work_schedules/${year}.json`,
      params: {month},
      mutationTypes: types.WORK_SCHEDULES_DETAIL
    })
  },

  listLazyFetch({ dispatch, getters, commit }, data) {
    const days = getters.getListByDate(data)

    commit('setCurrentPage', data)

    return new Promise((resolve, reject) => {
      if (days === undefined) {
        dispatch('listReadRequest', data).then(() => {
          resolve(getters.getListByDate(data))
        })
      } else {
        resolve(days)
      }
    })
  },

  toggleDay({commit, getters}, day) {
    const idx = getters.getIndexInCurrentList(day)

    if (idx >= 0) {
      commit('removeDay', day)
    } else {
      commit('addDay', day)
    }
  },

  update(store) {
    const data = store.state[types.WORK_SCHEDULES_LIST.stateKey]
    let items = []

    forIn(data, (value, key) => {
      items.push(
        {
          month: key,
          days: value.map(day => day.id)
        }
      )
    })

    return api.post(store, {items: items}, {
      url: ENDPOINT_URL,
      mutationTypes: types.WORK_SCHEDULES_SAVE
    })
  }
}

// mutations
const mutations = {
  [types.WORK_SCHEDULES_LIST.PENDING] (state) {
    state[types.WORK_SCHEDULES_LIST.loadingKey] = true
	},
  [types.WORK_SCHEDULES_LIST.SUCCESS] (state, {data, config}) {
    const key = buildKey(config.params)

    state[types.WORK_SCHEDULES_LIST.loadingKey] = false

    if (key && data) {
      state[types.WORK_SCHEDULES_LIST.stateKey][key] = data['data']['days'].map((item) => {
        return {
          id: item,
          date: item
        }
      })
    }

	},
  [types.WORK_SCHEDULES_LIST.FAILURE] (state, error) {
    state[types.WORK_SCHEDULES_LIST.loadingKey] = false
    state[types.WORK_SCHEDULES_LIST.errorKey] = error
	},

  // Save
  [types.WORK_SCHEDULES_SAVE.PENDING] (state) {
    state[types.WORK_SCHEDULES_SAVE.loadingKey] = true
	},
  [types.WORK_SCHEDULES_SAVE.SUCCESS] (state, {data}) {
    state[types.WORK_SCHEDULES_SAVE.loadingKey] = false
    state[types.WORK_SCHEDULES_SAVE.stateKey] = data
	},
  [types.WORK_SCHEDULES_SAVE.FAILURE] (state, error) {
    state[types.WORK_SCHEDULES_SAVE.loadingKey] = false
    state[types.WORK_SCHEDULES_SAVE.errorKey] = error
	},

  // Detail
  [types.WORK_SCHEDULES_DETAIL.PENDING] (state) {
    state[types.WORK_SCHEDULES_DETAIL.loadingKey] = true
	},
  [types.WORK_SCHEDULES_DETAIL.SUCCESS] (state, {data}) {
    state[types.WORK_SCHEDULES_DETAIL.loadingKey] = false
    state[types.WORK_SCHEDULES_DETAIL.stateKey] = data
	},
  [types.WORK_SCHEDULES_DETAIL.FAILURE] (state, error) {
    state[types.WORK_SCHEDULES_DETAIL.loadingKey] = false
    state[types.WORK_SCHEDULES_DETAIL.errorKey] = error
	},

  setCurrentPage(state, page) {
    state['WORK_SCHEDULES_CURRENT_PAGE'] = page
  },

  addDay(state, day) {
    const page = state['WORK_SCHEDULES_CURRENT_PAGE']
    const key = buildKey(page)
    let data = state[types.WORK_SCHEDULES_LIST.stateKey][key]

    data.push({
      id: day.id,
      date: day.date,
    })
  },
  removeDay(state, day) {
    const page = state['WORK_SCHEDULES_CURRENT_PAGE']
    const key = buildKey(page)
    let data = state[types.WORK_SCHEDULES_LIST.stateKey][key]
    const idx = data.findIndex(d => d.id === day.id)

    if (idx >= 0) {
      data.splice(idx, 1)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
