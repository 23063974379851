<template>
  <div class="card chat-app-wrapper">
    <div class="row mx-0">
      <div class="col-lg-12 col-md-8 px-0 d-flex flex-column">
        <div class="chat-container-wrapper">
          <Preloader v-if="listLoading" class="mt-30" />
          <div v-if="empty" class="text-muted mt-30">No comments yet.</div>
          <div class="chat-bubble" v-for="comment in comments" :key="comment.id" :class="`${comment.direction}-chat`">
            <slot name="comment" :comment="comment">
              <div class="chat-message">
                <div class="comment-controls" v-if="!comment.deleting && comment.deleteable">
                  <button type="button" class="input-group-text delete" @click="deleteComment(comment)">
                    <i class="mdi mdi-delete icon-sm"></i>
                  </button>
                </div>

                <p class="font-weight-bold">{{comment.author_name}}</p>
                <span v-html="comment.content_html"></span>
              </div>

              <div class="sender-details">
                <span v-if="comment.author_avatar_url">
                  <img :src="comment.author_avatar_url" class="sender-avatar img-xs rounded-circle" />
                </span>
                <span class="sender-avatar img-xs rounded-circle" v-else>
                  <Avatar :size="37" :name="comment.author_name" />
                </span>
                <p class="seen-text">
                  <span v-if="!comment.deleting && comment.created_at">
                    Message sent: {{comment.created_at}}
                  </span>
                  <span v-else>
                    <Dualball />
                  </span>
                </p>
              </div>
            </slot>
          </div>
        </div>
        <div v-if="allowCreate" class="chat-text-field mt-auto">
          <div class="input-group">
            <AutoResize ref="autoresize">
              <template v-slot:default="{resize}">
                <textarea
                  type="text"
                  class="form-control"
                  placeholder="Type a message here"
                  v-model.trim="message"
                  @keyup.shift.enter="createComment"
                  @input="resize"
                  ref="inputcontrol">
                </textarea>
              </template>
            </AutoResize>

            <div class="input-group-append">
              <button type="submit" class="input-group-text chat-button" :disabled="submitDisabled" @click="createComment"><i class="mdi mdi-send icon-sm"></i></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'

import Avatar from 'src/components/avatar.vue'
import Preloader from 'src/components/preloader.vue'
import Dualball from 'src/components/dualball.vue'
import AutoResize from 'src/components/auto_resize.vue'

export default defineComponent({
  props: {
    target: {
      type: String
    },
    uuid: {
      type: String
    },
    creatable: {
      type: String,
      default: '1'
    }
  },
  data() {
    return {
      message: null
    }
  },
  components: {
    Avatar,
    Preloader,
    Dualball,
    AutoResize
  },
  computed: {
    submitDisabled() {
      return !this.message || this.message.length == 0
    },
    empty() {
      return !this.listLoading && (!this.comments || this.comments.length == 0)
    },
    allowCreate() {
      return this.creatable == '1'
    },
    ...mapGetters('comments', {
      comments: 'list',
      listLoading: 'loading',
      error: 'error'
    })
  },
  watch: {
    error(ex) {
      if (ex && ex.message) {
        this.$toast.open({
          message: ex.message,
          type: 'error'
        })
      }
    }
  },
  methods: {
    createComment(){
      const { inputcontrol, autoresize } = this.$refs

      const comment = {
        content: this.message,
        content_html: `<p>${this.message}</p>`,
        target_id: this.uuid,
        target_type: this.target
      }

      this.createRequest(comment).then(({data}) => {
        if (data && data['data']) {
          this.$toast.open({
            message: 'Comment was successfully created.',
            type: 'success'
          })
        }
      })

      this.message = null
      inputcontrol.focus()
      autoresize.update()
    },
    deleteComment(comment) {
      if(confirm("Do you really want to delete?")){
        this.deleteRequest(comment.id).then(({data}) => {
          if (data && data['data']) {
            this.$toast.open({
              message: 'Comment was successfully deleted.',
              type: 'success'
            })
          }
        })
      }
    },
    ...mapActions('comments', [
      'listReadRequest',
      'createRequest',
      'deleteRequest'
    ]),
  },
  created() {
    const params = {
      [this.target]: this.uuid
    }

    this.listReadRequest(params)
	}
})
</script>
