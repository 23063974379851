<template>
  <div>
    <div v-if="loading">
      <Preloader />
    </div>
    <div class="table-responsive" v-else>
      <table class="table table-bordered table-hover">
        <thead>
          <tr>
            <th>ID</th>
            <th>Subject query</th>
            <th>State</th>
            <th>Assignee</th>
            <th>Created at</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="record in records" :key="record.id">
            <td>{{ record.id }}</td>
            <td>{{ record.subject_query_title }}</td>
            <td><state-label :name="record.state_name" /></td>
            <td>{{ record.assignee_name }}</td>
            <td>{{ record.created_at }}</td>
            <td>
              <a class="btn btn-gradient-success btn-sm mt5" :href="renderTicketLink(record.id)">View</a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState } from 'vuex'

import Preloader from 'src/components/preloader.vue'
import StateLabel from 'src/widgets/state_label.vue'

export default defineComponent({
  props: {
    target: {
      type: String
    },
  },
  components: {
    Preloader,
    StateLabel
  },
  created() {
    this.$store.dispatch('tickets/listReadRequest', {id: this.target})
	},
  computed: {
    ...mapState('tickets', {
      loading(state) {
        return state['TICKETS_LIST_LOADING']
      },
      records(state) {
        return state['TICKETS_LIST_DATA']
      }
    }),
  },
  methods: {
    renderTicketLink(ticket_id) {
      return `/orders/${this.target}/tickets/${ticket_id}`
    }
  }
})
</script>
