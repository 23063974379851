<template>
  <div>
    <div v-if="loading">
      <Preloader />
    </div>
    <div class="table-responsive" v-else>
      <table class="table table-bordered table-hover">
        <thead>
          <slot name="header"></slot>
        </thead>
        <tbody>
          <tr v-for="record in records" :key="record.id">
            <slot name="row" :record="record"></slot>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState } from 'vuex'

import Preloader from 'src/components/preloader.vue'

export default defineComponent({
  props: {
    target: {
      type: String
    },
    collection: {
      type: String
    }
  },
  components: {
    Preloader
  },
  computed: {
    ...mapState('orders', {
      loading(state) {
        return state['ORDER_DETAIL_READ_LOADING']
      },
      records(state) {
        const item = state['ORDER_DETAIL_READ_DATA']

        return item && item.data && item.data[this.collection]
      }
    }),
  }
})
</script>
