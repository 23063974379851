<template>
  <div class="avatar-uploader mb-3">
    <img class="img-lg rounded-circle" :src="image_path" />

    <uploader-app
      :plugins="['Webcam']"
      :config="config"
      :dashboard="dashboard"
      :params="params"
      :onBodyBuilder="onBodyBuilder"
      :onUpload="onUpload">
      <template v-slot:button="slotProps">
        <span class="edit-avatar-icon" @click="slotProps.onClick">
          <i class="mdi mdi-camera"></i>
        </span>
      </template>
    </uploader-app>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import UploaderApp from 'src/widgets/uploader_app'

export default defineComponent({
  props: {
    url: {
      type: String
    },
    target: {
      type: String
    }
  },
  data() {
    return {
      uploaded_url: null
    }
  },
  components: {
    UploaderApp
  },
  computed: {
    config() {
      return {
        autoProceed: false,
        restrictions: {
          maxFileSize: 1_000_000,
          maxTotalFileSize: 1_000_000,
          maxNumberOfFiles: 1,
          minNumberOfFiles: 1,
          allowedFileTypes: ['image/*'],
        },
      }
    },
    dashboard() {
      return {
        theme: 'light',
        inline: false,
        closeModalOnClickOutside: true,
        disablePageScrollWhenModalOpen: false,
        allowMultipleUploads: false,
        closeAfterFinish: true,
      }
    },
    params() {
      return {
        klass: 'Avatar',
        target: this.target
      }
    },
    image_path() {
      return this.uploaded_url || this.url
    }
  },
  methods: {
    onBodyBuilder(file, params) {
      return {
        filename: file.name,
        type: file.type,
        size: file.size,
        provider: 's3',
        klass: params.klass,
        assetable_id: params.target,
        assetable_type: 'RemoteQa'
      }
    },
    onUpload(file, data) {
      this.uploaded_url = data.uploadURL

      this.$store.dispatch('profiles/updateRequest').then(() => {
        this.$toast.open({
          message: 'Your avatar was successfully changed. Reload the page to see changes',
          type: 'success'
        })
      })
    }
  }
})
</script>

<style>
.avatar-uploader {
  position: relative;
}

.avatar-uploader .edit-avatar-icon {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  position: absolute;
  background-color: #d8d8d8;
  bottom: 0;
  right: 0;
  cursor: pointer;
}

.avatar-uploader .edit-avatar-icon i {
  padding: 7px 0 0 0px;
  display: block;
}

.avatar-uploader .edit-avatar-icon input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
</style>
